export const sth = [{
  id: 'Check Box 1',
  type: 'checkbox',
  fields: 'is_registration_form',
  parent: 'completion'
}, {
  id: 'Check Box 2',
  type: 'checkbox',
  fields: 'is_repentance_testimony_form',
  parent: 'completion'
}, {
  id: 'Check Box 7',
  type: 'checkbox',
  fields: 'is_faith_calling_form',
  parent: 'completion'
}, {
  id: 'Check Box 4',
  type: 'checkbox',
  fields: 'is_couple_agreement_form',
  parent: 'completion'
}, {
  id: 'Check Box 5',
  type: 'checkbox',
  fields: 'is_health_form',
  parent: 'completion'
}, {
  id: 'Check Box 6',
  type: 'checkbox',
  fields: 'is_financial_support_form',
  parent: 'completion'
}, {
  id: 'Check Box 8',
  type: 'checkbox',
  fields: 'is_recommendation_form_result',
  parent: 'completion'
}, {
  id: 'Check Box 9',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 11',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 12',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 13',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 14',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  // TRANSFER
  id: 'Check Box 16',
  type: 'checkbox',
  fields: 'payment_method',
  parent: 'payment',
  value: "Transfer"
}, {
  id: 'Button4',
  type: 'radio',
  fields: 'concentration',
  parent: 'concentration',
  option: [{
    value: 'Pendidikan Kristen',
    select: '<2>'
  }, {
    value: 'Penggembalaan',
    select: '<1>'
  }, {
    value: 'Ibadah',
    select: '<3>'
  }]
}, {
  id: 'Nama_Lengkap',
  type: 'text',
  fields: 'fullname',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 1',
  type: 'text',
  fields: 'birth_place',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 2',
  type: 'text',
  fields: 'nationality',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 3',
  type: 'text',
  fields: 'email',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 4',
  type: 'text',
  fields: 'address',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 6',
  type: 'text',
  fields: 'city',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 8',
  type: 'text',
  fields: 'phone',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 11',
  type: 'text',
  fields: 'id_passport_number',
  parent: 'personal_data'
}, {
  id: 'Combo Box 3',
  type: 'date',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Combo Box 4',
  type: 'month',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Text Field 72',
  type: 'year',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Jenis Kelamin',
  type: 'radio',
  fields: 'gender',
  parent: 'personal_data',
  options: [{
    value: 'Laki-laki',
    select: 0
  }, {
    value: 'Perempuan',
    select: 1
  }]
}, {
  id: 'Button1',
  type: 'file',
  fields: 'self_pict_url',
  parent: 'personal_data'
}, {
  id: 'Combo Box 11',
  type: 'select',
  fields: 'role-1',
  parent: 'family_data'
}, {
  id: 'Combo Box 12',
  type: 'select',
  fields: 'role-2',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 13',
  type: 'text',
  fields: 'name-1',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 14',
  type: 'text',
  fields: 'name-2',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 17',
  type: 'text',
  fields: 'phone-1',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 18',
  type: 'text',
  fields: 'phone-2',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 93',
  type: 'text',
  fields: 'job-1',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 110',
  type: 'text',
  fields: 'job-2',
  parent: 'family_data'
}, {
  id: 'Combo Box 13',
  type: 'select',
  fields: 'birth_order',
  parent: 'family_data'
}, {
  id: 'Combo Box 14',
  type: 'select',
  fields: 'total_sibling',
  parent: 'family_data'
}, {
  id: 'Button5',
  type: 'radio',
  fields: 'is_marriage',
  parent: 'marriage_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Button6',
  type: 'radio',
  fields: 'is_divorce',
  parent: 'marriage_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Gangguan Jiwa',
  type: 'radio',
  fields: 'is_mental_illness',
  parent: 'health_history_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Text Field 2',
  type: 'text',
  fields: 'mental_illness_desc',
  parent: 'health_history_data'
}, {
  id: 'Nama_Lengkap 34',
  type: 'text',
  fields: 'mental_illness_family',
  parent: 'health_history_data'
}, {
  id: 'Nama_Lengkap 35',
  type: 'text',
  fields: 'illness_desc',
  parent: 'health_history_data'
}, {
  id: 'Text Field 3',
  type: 'text',
  fields: 'exist_illness_desc',
  parent: 'health_history_data'
}, {
  id: 'Nama_Lengkap 46',
  type: 'text',
  fields: 'place-job1',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 47',
  type: 'text',
  fields: 'position-job1',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 48',
  type: 'text',
  fields: 'place-job2',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 49',
  type: 'text',
  fields: 'position-job2',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 50',
  type: 'text',
  fields: 'place-job3',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 51',
  type: 'text',
  fields: 'position-job3',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 52',
  type: 'text',
  fields: 'place-job4',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 53',
  type: 'text',
  fields: 'position-job4',
  parent: 'job_history_data'
}, {
  id: 'Text Field 56',
  type: 'text',
  fields: 'start-job1',
  parent: 'job_history_data'
}, {
  id: 'Text Field 57',
  type: 'text',
  fields: 'end-job1',
  parent: 'job_history_data'
}, {
  id: 'Text Field 59',
  type: 'text',
  fields: 'start-job2',
  parent: 'job_history_data'
}, {
  id: 'Text Field 58',
  type: 'text',
  fields: 'end-job2',
  parent: 'job_history_data'
}, {
  id: 'Text Field 60',
  type: 'text',
  fields: 'start-job3',
  parent: 'job_history_data'
}, {
  id: 'Text Field 61',
  type: 'text',
  fields: 'end-job3',
  parent: 'job_history_data'
}, {
  id: 'Text Field 62',
  type: 'text',
  fields: 'start-job4',
  parent: 'job_history_data'
}, {
  id: 'Text Field 63',
  type: 'text',
  fields: 'end-job4',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 55',
  type: 'text',
  fields: 'position-service1',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 56',
  type: 'text',
  fields: 'position-service2',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 57',
  type: 'text',
  fields: 'position-service3',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 58',
  type: 'text',
  fields: 'position-service4',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 59',
  type: 'text',
  fields: 'church-service4',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 60',
  type: 'text',
  fields: 'church-service3',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 61',
  type: 'text',
  fields: 'church-service2',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 113',
  type: 'text',
  fields: 'church-service1',
  parent: 'service_history_data'
}, {
  id: 'Text Field 64',
  type: 'text',
  fields: 'start-service1',
  parent: 'service_history_data'
}, {
  id: 'Text Field 65',
  type: 'text',
  fields: 'end-service1',
  parent: 'service_history_data'
}, {
  id: 'Text Field 71',
  type: 'text',
  fields: 'start-service2',
  parent: 'service_history_data'
}, {
  id: 'Text Field 66',
  type: 'text',
  fields: 'end-service2',
  parent: 'service_history_data'
}, {
  id: 'Text Field 70',
  type: 'text',
  fields: 'start-service3',
  parent: 'service_history_data'
}, {
  id: 'Text Field 67',
  type: 'text',
  fields: 'end-service3',
  parent: 'service_history_data'
}, {
  id: 'Text Field 69',
  type: 'text',
  fields: 'start-service4',
  parent: 'service_history_data'
}, {
  id: 'Text Field 68',
  type: 'text',
  fields: 'end-service4',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 64',
  type: 'text',
  fields: 'church_member',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 65',
  type: 'text',
  fields: 'church_address',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 66',
  type: 'text',
  fields: 'church_city',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 68',
  type: 'text',
  fields: 'church_phone',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 69',
  type: 'text',
  fields: 'church_email',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 114',
  type: 'text',
  fields: 'church_senior_pastor',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 115',
  type: 'text',
  fields: 'church_active',
  parent: 'church_member_data'
}, {
  id: 'RB_Memiliki waktu teduh',
  type: 'radio',
  fields: 'is_silent_time',
  parent: 'personal_spiritual_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Combo Box 51',
  type: 'select',
  fields: 'spiritually_number',
  parent: 'personal_spiritual_data'
}, {
  id: 'Berapa kali baca alkitab',
  type: 'text',
  fields: 'full_bible_read_amount',
  parent: 'personal_spiritual_data'
}, {
  id: 'RB_Memiliki sahabat atau mentor',
  type: 'radio',
  fields: 'is_spiritual_friend',
  parent: 'personal_spiritual_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Text Field 5',
  type: 'text',
  fields: 'spiritual_friend_name',
  parent: 'personal_spiritual_data'
}, {
  id: 'Nama_Lengkap 117',
  type: 'text',
  fields: 'bad_habit',
  parent: 'personal_struggle_data'
}, {
  id: 'Nama_Lengkap 118',
  type: 'text',
  fields: 'overcome_effort',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 190',
  type: 'checkBox',
  fields: 'is_drug',
  parent: 'personal_struggle_data',
}, {
  id: 'Check Box 191',
  type: 'checkBox',
  fields: 'is_gaming',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 192',
  type: 'checkBox',
  fields: 'is_alcohol',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 193',
  type: 'checkBox',
  fields: 'is_smoking',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 194',
  type: 'checkBox',
  fields: 'is_gambling',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 195',
  type: 'checkBox',
  fields: 'is_porn',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 196',
  type: 'checkBox',
  fields: 'is_masturbate',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 197',
  type: 'checkBox',
  fields: 'is_other',
  parent: 'personal_struggle_data',
}, {
  id: 'Button7',
  type: 'radio',
  fields: 'is_ever_register_stt',
  parent: 'other_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Button8',
  type: 'radio',
  fields: 'is_ever_finish_stt',
  parent: 'other_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Nama_Lengkap 111',
  type: 'text',
  fields: 'when_register_stt',
  parent: 'other_data'
}, {
  id: 'Nama_Lengkap 112',
  type: 'text',
  fields: 'which_register_stt',
  parent: 'other_data'
}, {
  id: 'Text Field 6',
  type: 'text',
  fields: 'other_desc',
  parent: 'other_data'
}, {
  id: 'Check Box 31',
  type: 'checkBox',
  fields: 'is_alumni',
  parent: 'other_data'
}, {
  id: 'Check Box 32',
  type: 'checkBox',
  fields: 'is_servant_of_god',
  parent: 'other_data'
}, {
  id: 'Check Box 33',
  type: 'checkBox',
  fields: 'is_friend',
  parent: 'other_data'
}, {
  id: 'Check Box 34',
  type: 'checkBox',
  fields: 'is_lecturer',
  parent: 'other_data'
}, {
  id: 'Check Box 36',
  type: 'checkBox',
  fields: 'is_college_student',
  parent: 'other_data'
}, {
  id: 'Check Box 37',
  type: 'checkBox',
  fields: 'is_family',
  parent: 'other_data'
}, {
  id: 'Check Box 38',
  type: 'checkBox',
  fields: 'is_website',
  parent: 'other_data'
}, {
  id: 'Check Box 39',
  type: 'checkBox',
  fields: 'is_social_media',
  parent: 'other_data'
}, {
  id: 'Check Box 40',
  type: 'checkBox',
  fields: 'is_communication_media',
  parent: 'other_data'
}, {
  id: 'Check Box 41',
  type: 'checkBox',
  fields: 'is_poster',
  parent: 'other_data'
}, {
  id: 'Check Box 42',
  type: 'checkBox',
  fields: 'is_other',
  parent: 'other_data'
}, {
  id: 'Text Field 49',
  type: 'text',
  fields: 'other_desc',
  parent: 'personal_struggle_data',
}, {
  id: 'Text Field 52',
  type: 'text',
  fields: 'guardian_name',
  parent: 'agreement_data'
}, {
  id: 'Text Field 51',
  type: 'text',
  fields: 'fullname',
  parent: 'personal_data'
}, {
  id: 'Button2',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Button3',
  type: 'file',
  fields: 'guardian_sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Combo Box 156',
  type: 'date',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Combo Box 157',
  type: 'month',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Text Field 73',
  type: 'year',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 123',
  type: 'text',
  fields: 'name_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 124',
  type: 'text',
  fields: 'name_b',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 125',
  type: 'text',
  fields: 'email_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 126',
  type: 'text',
  fields: 'email_b',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 127',
  type: 'text',
  fields: 'phone_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 128',
  type: 'text',
  fields: 'phone_b',
  parent: 'recommendation_form'
}, {
  id: 'Text Field 22',
  type: 'text',
  fields: 'repentance_story',
  parent: 'repentance_story'
}, {
  id: 'Text Field 23',
  type: 'text',
  fields: 'calling_faith_story',
  parent: 'calling_faith_story'
}]
export const coupleAgreement = [{
  id: 'Button1',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'coupleAgreement'
}, {
  id: 'Nama_Lengkap 89',
  type: 'text',
  fields: 'name',
  parent: 'coupleAgreement'
}, {
  id: 'Nama_Lengkap 97',
  type: 'text',
  fields: 'address',
  parent: 'coupleAgreement'
}, {
  id: 'Nama_Lengkap 98',
  type: 'text',
  fields: 'city',
  parent: 'coupleAgreement'
}, {
  id: 'Nama_Lengkap 99',
  type: 'text',
  fields: 'phone',
  parent: 'coupleAgreement'
}, {
  id: 'Nama_Lengkap 101',
  type: 'text',
  fields: 'email',
  parent: 'coupleAgreement'
}, {
  id: 'Nama_Lengkap 100',
  type: 'text',
  fields: 'candidate_name',
  parent: 'coupleAgreement'
}, {
  id: 'Text Field 60',
  type: 'text',
  fields: 'name',
  parent: 'coupleAgreement'
}, {
  id: 'Combo Box 136',
  type: 'select',
  fields: 'candidate_relation_status',
  parent: 'coupleAgreement',
  option: ['suami', 'istri'],
}, {
  id: 'Combo Box 137',
  type: 'select',
  fields: 'relationship',
  parent: 'coupleAgreement',
  option: ['suami', 'istri'],
}, {
  id: 'Combo Box 138',
  type: 'select',
  fields: 'prodi',
  parent: 'coupleAgreement',
  option: ['S.Th.', 'M.Div.', 'M.Min.', 'M.Th.', 'Sertifikat Teologi']
}, {
  id: 'Combo Box 139',
  type: 'date',
  fields: 'sign_date',
  parent: 'coupleAgreement'
}, {
  id: 'Combo Box 140',
  type: 'month',
  fields: 'sign_date',
  parent: 'coupleAgreement'
}, {
  id: 'Text Field 61',
  type: 'year',
  fields: 'sign_date',
  parent: 'coupleAgreement'
}]
export const sert = [{
  id: 'Check Box 1',
  type: 'checkbox',
  fields: 'is_registration_form',
  parent: 'completion'
}, {
  id: 'Check Box 2',
  type: 'checkbox',
  fields: 'is_repentance_testimony_form',
  parent: 'completion'
}, {
  id: 'Check Box 13',
  type: 'checkbox',
  fields: 'is_motivation_form',
  parent: 'completion'
}, {
  id: 'Check Box 3',
  type: 'checkbox',
  fields: 'is_couple_agreement_form',
  parent: 'completion'
}, {
  id: 'Check Box 4',
  type: 'checkbox',
  fields: 'is_financial_support_form',
  parent: 'completion'
}, {
  id: 'Check Box 5',
  type: 'checkbox',
  fields: 'is_recommendation_form_result',
  parent: 'completion'
}, {
  id: 'Check Box 6',
  type: 'checkbox',
  fields: 'is_recommendation_form_result',
  parent: 'completion'
}, {
  id: 'Check Box 9',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 10',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 11',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 12',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 237',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 238',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  // QRIS
  id: 'Check Box 15',
  type: 'checkbox',
  fields: 'payment_method',
  parent: 'payment',
  value: "QRIS"
}, {
  // TRANSFER
  id: 'Check Box 16',
  type: 'checkbox',
  fields: 'payment_method',
  parent: 'payment',
  value: "Transfer"
}, {
  id: 'Konsentrasi',
  type: 'radio',
  fields: 'concentration',
  parent: 'concentration',
  option: [{
    value: 'Pendidikan Kristen',
    select: 'PK'
  }, {
    value: 'Pelayanan Gerejawi',
    select: 'Penggembalaan'
  }]
}, {
  id: 'self_pict',
  type: 'file',
  fields: 'self_pict_url',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap',
  type: 'text',
  fields: 'fullname',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 1',
  type: 'text',
  fields: 'birth_place',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 2',
  type: 'text',
  fields: 'nationality',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 3',
  type: 'text',
  fields: 'email',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 4',
  type: 'text',
  fields: 'address',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 5',
  type: 'text',
  fields: 'non_reside_name',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 6',
  type: 'text',
  fields: 'city',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 7',
  type: 'text',
  fields: 'postal_code',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 8',
  type: 'text',
  fields: 'home_phone',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 9',
  type: 'text',
  fields: 'phone',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 10',
  type: 'text',
  fields: 'non_reside_phone',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 11',
  type: 'text',
  fields: 'id_passport_number',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 12',
  type: 'text',
  fields: 'na_passport_number',
  parent: 'personal_data'
}, {
  id: 'Combo Box 3',
  type: 'date',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Combo Box 4',
  type: 'month',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Text1',
  type: 'year',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Jenis Kelamin',
  type: 'radio',
  fields: 'gender',
  parent: 'personal_data',
  options: [{
    value: 'Laki-laki',
    select: 0
  }, {
    value: 'Perempuan',
    select: 1
  }]
}, {
  id: 'Nama_Lengkap 14',
  type: 'text',
  fields: 'name-1',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 15',
  type: 'text',
  fields: 'name-2',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 16',
  type: 'text',
  fields: 'phone-1',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 17',
  type: 'text',
  fields: 'phone-2',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 18',
  type: 'text',
  fields: 'job-1',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 34',
  type: 'text',
  fields: 'job-2',
  parent: 'family_data'
}, {
  id: 'Combo Box 13',
  type: 'select',
  fields: 'birth_order',
  parent: 'family_data'
}, {
  id: 'Combo Box 14',
  type: 'select',
  fields: 'total_sibling',
  parent: 'family_data'
}, {
  id: 'Combo Box 130',
  type: 'select',
  fields: 'role-1',
  parent: 'family_data'
}, {
  id: 'Combo Box 131',
  type: 'select',
  fields: 'role-2',
  parent: 'family_data'
}, {
  id: 'Combo Box 16',
  type: 'month',
  fields: 'marriage_date',
  parent: 'marriage_data'
}, {
  id: 'Combo Box 17',
  type: 'date',
  fields: 'marriage_date',
  parent: 'marriage_data'
}, {
  id: 'Combo Box 166',
  type: 'year',
  fields: 'marriage_date',
  parent: 'marriage_data'
},{
  id: 'Nama_Lengkap 19',
  type: 'text',
  fields: 'name',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 24',
  type: 'text',
  fields: 'last_education',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 20',
  type: 'text',
  fields: 'name-anak-1',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 25',
  type: 'text',
  fields: 'edu-anak-1',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 21',
  type: 'text',
  fields: 'name-anak-2',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 26',
  type: 'text',
  fields: 'edu-anak-2',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 22',
  type: 'text',
  fields: 'name-anak-3',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 27',
  type: 'text',
  fields: 'edu-anak-3',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 23',
  type: 'text',
  fields: 'name-anak-4',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 28',
  type: 'text',
  fields: 'edu-anak-4',
  parent: 'marriage_data'
}, {
  id: 'Pernah Cerai',
  type: 'radio',
  fields: 'is_divorce',
  parent: 'marriage_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Combo Box 18',
  type: 'select',
  fields: 'relationship',
  parent: 'marriage_data',
  option: ['suami', 'istri'],
}, {
  id: 'Pernah Cerai',
  type: 'radio',
  fields: 'is_divorce',
  parent: 'marriage_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Combo Box 24',
  type: 'month',
  fields: 'divorce_date',
  parent: 'marriage_data'
}, {
  id: 'Combo Box 25',
  type: 'date',
  fields: 'divorce_date',
  parent: 'marriage_data'
}, {
  id: 'Combo Box 167',
  type: 'year',
  fields: 'divorce_date',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 45',
  type: 'text',
  fields: 'school_name-sd',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 116',
  type: 'text',
  fields: 'school_name-smp',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 117',
  type: 'text',
  fields: 'school_name-sma',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 39',
  type: 'text',
  fields: 'city-sd',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 40',
  type: 'text',
  fields: 'city-smp',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 41',
  type: 'text',
  fields: 'city-sma',
  parent: 'education_history_data'
}, {
  id: 'Text2',
  type: 'year',
  fields: 'year-sd',
  parent: 'education_history_data'
}, {
  id: 'Text3',
  type: 'year',
  fields: 'year-smp',
  parent: 'education_history_data'
}, {
  id: 'Text4',
  type: 'year',
  fields: 'year-sma',
  parent: 'education_history_data'
}, {
  id: 'Combo Box 110',
  type: 'select',
  fields: 'education_level-1',
  parent: 'education_history_data'
}, {
  id: 'Combo Box 111',
  type: 'select',
  fields: 'education_level-2',
  parent: 'education_history_data'
}, {
  id: 'Combo Box 112',
  type: 'select',
  fields: 'education_level-3',
  parent: 'education_history_data'
}, {
  id: 'Combo Box 113',
  type: 'select',
  fields: 'education_level-4',
  parent: 'education_history_data'
}, {
  id: 'Text5',
  type: 'year',
  fields: 'year-1',
  parent: 'education_history_data'
}, {
  id: 'Text6',
  type: 'year',
  fields: 'year-2',
  parent: 'education_history_data'
}, {
  id: 'Text7',
  type: 'year',
  fields: 'year-3',
  parent: 'education_history_data'
}, {
  id: 'Text8',
  type: 'year',
  fields: 'year-4',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 62',
  type: 'text',
  fields: 'school_name-spesial',
  parent: 'education_history_data'
}, {
  id: 'Text9',
  type: 'year',
  fields: 'year-spesial',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 54',
  type: 'text',
  fields: 'school_name-1',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 121',
  type: 'text',
  fields: 'school_name-2',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 122',
  type: 'text',
  fields: 'school_name-3',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 56',
  type: 'text',
  fields: 'school_name-4',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 42',
  type: 'text',
  fields: 'city-1',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 43',
  type: 'text',
  fields: 'city-2',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 44',
  type: 'text',
  fields: 'city-3',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 55',
  type: 'text',
  fields: 'city-4',
  parent: 'education_history_data'
}, {
  id: 'Nama_Lengkap 46',
  type: 'text',
  fields: 'place-job1',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 47',
  type: 'text',
  fields: 'position-job1',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 48',
  type: 'text',
  fields: 'place-job2',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 49',
  type: 'text',
  fields: 'position-job2',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 50',
  type: 'text',
  fields: 'place-job3',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 51',
  type: 'text',
  fields: 'position-job3',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 52',
  type: 'text',
  fields: 'place-job4',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 53',
  type: 'text',
  fields: 'position-job4',
  parent: 'job_history_data'
}, {
  id: 'Text10',
  type: 'text',
  fields: 'start-job1',
  parent: 'job_history_data'
}, {
  id: 'Text14',
  type: 'text',
  fields: 'end-job1',
  parent: 'job_history_data'
}, {
  id: 'Text11',
  type: 'text',
  fields: 'start-job2',
  parent: 'job_history_data'
}, {
  id: 'Text15',
  type: 'text',
  fields: 'end-job2',
  parent: 'job_history_data'
}, {
  id: 'Text12',
  type: 'text',
  fields: 'start-job3',
  parent: 'job_history_data'
}, {
  id: 'Text16',
  type: 'text',
  fields: 'end-job3',
  parent: 'job_history_data'
}, {
  id: 'Text13',
  type: 'text',
  fields: 'start-job4',
  parent: 'job_history_data'
}, {
  id: 'Text17',
  type: 'text',
  fields: 'end-job4',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 61',
  type: 'text',
  fields: 'position-service1',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 70',
  type: 'text',
  fields: 'position-service2',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 71',
  type: 'text',
  fields: 'position-service3',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 123',
  type: 'text',
  fields: 'position-service4',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 60',
  type: 'text',
  fields: 'church-service4',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 59',
  type: 'text',
  fields: 'church-service3',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 58',
  type: 'text',
  fields: 'church-service2',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 57',
  type: 'text',
  fields: 'church-service1',
  parent: 'service_history_data'
}, {
  id: 'Text18',
  type: 'text',
  fields: 'start-service1',
  parent: 'service_history_data'
}, {
  id: 'Text22',
  type: 'text',
  fields: 'end-service1',
  parent: 'service_history_data'
}, {
  id: 'Text19',
  type: 'text',
  fields: 'start-service2',
  parent: 'service_history_data'
}, {
  id: 'Text23',
  type: 'text',
  fields: 'end-service2',
  parent: 'service_history_data'
}, {
  id: 'Text20',
  type: 'text',
  fields: 'start-service3',
  parent: 'service_history_data'
}, {
  id: 'Text24',
  type: 'text',
  fields: 'end-service3',
  parent: 'service_history_data'
}, {
  id: 'Text21',
  type: 'text',
  fields: 'start-service4',
  parent: 'service_history_data'
}, {
  id: 'Text25',
  type: 'text',
  fields: 'end-service4',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 63',
  type: 'text',
  fields: 'church_member',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 64',
  type: 'text',
  fields: 'church_address',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 65',
  type: 'text',
  fields: 'church_city',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 66',
  type: 'text',
  fields: 'church_postal_code',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 67',
  type: 'text',
  fields: 'church_phone',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 68',
  type: 'text',
  fields: 'church_email',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 69',
  type: 'text',
  fields: 'church_senior_pastor',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 124',
  type: 'text',
  fields: 'church_active',
  parent: 'church_member_data'
}, {
  id: 'Text Field 44',
  type: 'text',
  fields: 'spiritual_friend_name',
  parent: 'personal_spiritual_data'
}, {
  id: 'Berapa kali baca alkitab 2',
  type: 'text',
  fields: 'full_bible_read_amount',
  parent: 'personal_spiritual_data'
}, {
  id: 'Combo Box 126',
  type: 'select',
  fields: 'spiritually_number',
  parent: 'personal_spiritual_data'
}, {
  id: 'RB_Memiliki waktu teduh',
  type: 'radio',
  fields: 'is_silent_time',
  parent: 'personal_spiritual_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'RB_Memiliki sahabat atau mentor',
  type: 'radio',
  fields: 'is_spiritual_friend',
  parent: 'personal_spiritual_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Text Field 5',
  type: 'text',
  fields: 'bad_habit',
  parent: 'personal_struggle_data'
}, {
  id: 'Text Field 40',
  type: 'text',
  fields: 'overcome_effort',
  parent: 'personal_struggle_data'
}, {
  id: 'Text Field 9',
  type: 'text',
  fields: 'sign_place',
  parent: 'agreement_data'
}, {
  id: 'Text Field 8',
  type: 'text',
  fields: 'fullname',
  parent: 'personal_data'
}, {
  id: 'Text Field 45',
  type: 'text',
  fields: 'repentance_story',
  parent: 'repentance_story'
}, {
  id: 'Text Field 24',
  type: 'text',
  fields: 'motivation_story',
  parent: 'motivation_story'
}, {
  id: 'Nama_Lengkap 125',
  type: 'text',
  fields: 'name_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 127',
  type: 'text',
  fields: 'email_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 129',
  type: 'text',
  fields: 'phone_a',
  parent: 'recommendation_form'
}, {
  id: 'Text Field 41',
  type: 'text',
  fields: 'other_desc',
  parent: 'other_data'
}, {
  id: 'Check Box 217',
  type: 'checkBox',
  fields: 'is_alumni',
  parent: 'other_data'
}, {
  id: 'Check Box 218',
  type: 'checkBox',
  fields: 'is_servant_of_god',
  parent: 'other_data'
}, {
  id: 'Check Box 219',
  type: 'checkBox',
  fields: 'is_friend',
  parent: 'other_data'
}, {
  id: 'Check Box 220',
  type: 'checkBox',
  fields: 'is_lecturer',
  parent: 'other_data'
}, {
  id: 'Check Box 221',
  type: 'checkBox',
  fields: 'is_college_student',
  parent: 'other_data'
}, {
  id: 'Check Box 222',
  type: 'checkBox',
  fields: 'is_family',
  parent: 'other_data'
}, {
  id: 'Check Box 223',
  type: 'checkBox',
  fields: 'is_website',
  parent: 'other_data'
}, {
  id: 'Check Box 224',
  type: 'checkBox',
  fields: 'is_social_media',
  parent: 'other_data'
}, {
  id: 'Check Box 225',
  type: 'checkBox',
  fields: 'is_communication_media',
  parent: 'other_data'
}, {
  id: 'Check Box 226',
  type: 'checkBox',
  fields: 'is_poster',
  parent: 'other_data'
}, {
  id: 'Check Box 227',
  type: 'checkBox',
  fields: 'is_other',
  parent: 'other_data'
}, {
  id: 'Combo Box 54',
  type: 'date',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Combo Box 55',
  type: 'month',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Text26',
  type: 'year',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'sign',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}]
export const mdiv = [{
  id: 'Check Box 1',
  type: 'checkbox',
  fields: 'is_registration_form',
  parent: 'completion'
}, {
  id: 'Check Box 2',
  type: 'checkbox',
  fields: 'is_repentance_testimony_form',
  parent: 'completion'
}, {
  id: 'Check Box 7',
  type: 'checkbox',
  fields: 'is_faith_calling_form',
  parent: 'completion'
}, {
  id: 'Check Box 3',
  type: 'checkbox',
  fields: 'is_motivation_form',
  parent: 'completion'
}, {
  id: 'Check Box 4',
  type: 'checkbox',
  fields: 'is_couple_agreement_form',
  parent: 'completion'
}, {
  id: 'Check Box 5',
  type: 'checkbox',
  fields: 'is_health_form',
  parent: 'completion'
}, {
  id: 'Check Box 6',
  type: 'checkbox',
  fields: 'is_financial_support_form',
  parent: 'completion'
}, {
  id: 'Check Box 8',
  type: 'checkbox',
  fields: 'is_recommendation_form_result',
  parent: 'completion'
}, {
  id: 'Check Box 9',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 10',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 11',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 12',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 13',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 14',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  // QRIS
  id: 'Check Box 15',
  type: 'checkbox',
  fields: 'payment_method',
  parent: 'payment',
  value: "QRIS"
}, {
  // TRANSFER
  id: 'Check Box 16',
  type: 'checkbox',
  fields: 'payment_method',
  parent: 'payment',
  value: "Transfer"
}, {
  id: 'Konsentrasi',
  type: 'radio',
  fields: 'concentration',
  parent: 'concentration',
  option: [{
    value: 'Pendidikan Kristen',
    select: 'PK'
  }, {
    value: 'Penggembalaan',
    select: 'Penggembalaan'
  }]
}, {
  id: 'Nama_Lengkap',
  type: 'text',
  fields: 'fullname',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 1',
  type: 'text',
  fields: 'birth_place',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 2',
  type: 'text',
  fields: 'nationality',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 3',
  type: 'text',
  fields: 'email',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 4',
  type: 'text',
  fields: 'address',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 5',
  type: 'text',
  fields: 'non_reside_name',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 6',
  type: 'text',
  fields: 'city',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 7',
  type: 'text',
  fields: 'postal_code',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 8',
  type: 'text',
  fields: 'home_phone',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 9',
  type: 'text',
  fields: 'phone',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 10',
  type: 'text',
  fields: 'non_reside_phone',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 11',
  type: 'text',
  fields: 'id_passport_number',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 12',
  type: 'text',
  fields: 'na_passport_number',
  parent: 'personal_data'
}, {
  id: 'Combo Box 3',
  type: 'date',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Combo Box 4',
  type: 'month',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Combo Box 111',
  type: 'year',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Jenis Kelamin',
  type: 'radio',
  fields: 'gender',
  parent: 'personal_data',
  options: [{
    value: 'Laki-laki',
    select: 0
  }, {
    value: 'Perempuan',
    select: 1
  }]
}, {
  id: 'self_pict',
  type: 'file',
  fields: 'self_pict_url',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 14',
  type: 'text',
  fields: 'name-1',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 15',
  type: 'text',
  fields: 'name-2',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 16',
  type: 'text',
  fields: 'dob-1',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 17',
  type: 'text',
  fields: 'dob-2',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 18',
  type: 'text',
  fields: 'phone-1',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 111',
  type: 'text',
  fields: 'phone-2',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 110',
  type: 'text',
  fields: 'job-1',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 112',
  type: 'text',
  fields: 'job-2',
  parent: 'family_data'
}, {
  id: 'Combo Box 13',
  type: 'select',
  fields: 'birth_order',
  parent: 'family_data'
}, {
  id: 'Combo Box 14',
  type: 'select',
  fields: 'total_sibling',
  parent: 'family_data'
}, {
  id: 'Combo Box 109',
  type: 'select',
  fields: 'role-1',
  parent: 'family_data'
}, {
  id: 'Combo Box 110',
  type: 'select',
  fields: 'role-2',
  parent: 'family_data'
}, {
  id: 'Combo Box 16',
  type: 'month',
  fields: 'marriage_date',
  parent: 'marriage_data'
}, {
  id: 'Combo Box 17',
  type: 'date',
  fields: 'marriage_date',
  parent: 'marriage_data'
}, {
  id: 'Combo Box 118',
  type: 'year',
  fields: 'marriage_date',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 19',
  type: 'text',
  fields: 'name',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 24',
  type: 'text',
  fields: 'dob',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 29',
  type: 'text',
  fields: 'last_education',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 20',
  type: 'text',
  fields: 'name-anak-1',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 25',
  type: 'text',
  fields: 'dob-anak-1',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 30',
  type: 'text',
  fields: 'edu-anak-1',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 21',
  type: 'text',
  fields: 'name-anak-2',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 26',
  type: 'text',
  fields: 'dob-anak-2',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 31',
  type: 'text',
  fields: 'edu-anak-2',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 22',
  type: 'text',
  fields: 'name-anak-3',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 27',
  type: 'text',
  fields: 'dob-anak-3',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 32',
  type: 'text',
  fields: 'edu-anak-3',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 23',
  type: 'text',
  fields: 'name-anak-4',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 28',
  type: 'text',
  fields: 'dob-anak-4',
  parent: 'marriage_data'
}, {
  id: 'Nama_Lengkap 33',
  type: 'text',
  fields: 'edu-anak-4',
  parent: 'marriage_data'
}, {
  id: 'Combo Box 18',
  type: 'select',
  fields: 'relationship',
  parent: 'marriage_data',
  option: ['suami', 'istri'],
}, {
  id: 'Pernah Cerai',
  type: 'radio',
  fields: 'is_divorce',
  parent: 'marriage_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Combo Box 24',
  type: 'month',
  fields: 'divorce_date',
  parent: 'marriage_data'
}, {
  id: 'Combo Box 25',
  type: 'date',
  fields: 'divorce_date',
  parent: 'marriage_data'
}, {
  id: 'Combo Box 119',
  type: 'year',
  fields: 'divorce_date',
  parent: 'marriage_data'
}, {
  id: 'Gangguan Jiwa',
  type: 'radio',
  fields: 'is_mental_illness',
  parent: 'health_history_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Text Field 2',
  type: 'text',
  fields: 'mental_illness_desc',
  parent: 'health_history_data'
}, {
  id: 'Nama_Lengkap 34',
  type: 'text',
  fields: 'mental_illness_family',
  parent: 'health_history_data'
}, {
  id: 'Text Field 3',
  type: 'text',
  fields: 'illness_desc',
  parent: 'health_history_data'
}, {
  id: 'Nama_Lengkap 35',
  type: 'text',
  fields: 'name-1',
  parent: 'skill_data'
}, {
  id: 'Combo Box 26',
  type: 'select',
  fields: 'level-1',
  parent: 'skill_data',
  option: ['Dasar', 'Lanjut', 'Menengah']
},{
  id: 'Nama_Lengkap 36',
  type: 'text',
  fields: 'name-2',
  parent: 'skill_data'
}, {
  id: 'Combo Box 27',
  type: 'select',
  fields: 'level-2',
  parent: 'skill_data',
  option: ['Dasar', 'Lanjut', 'Menengah']
}, {
  id: 'Nama_Lengkap 37',
  type: 'text',
  fields: 'name-3',
  parent: 'skill_data'
}, {
  id: 'Combo Box 28',
  type: 'select',
  fields: 'level-3',
  parent: 'skill_data',
  option: ['Dasar', 'Lanjut', 'Menengah']
}, {
  id: 'Nama_Lengkap 38',
  type: 'text',
  fields: 'name-4',
  parent: 'skill_data'
}, {
  id: 'Combo Box 29',
  type: 'select',
  fields: 'level-4',
  parent: 'skill_data',
  option: ['Dasar', 'Lanjut', 'Menengah']
}, {
  id: 'Nama_Lengkap 45',
  type: 'text',
  fields: 'college-university1',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 116',
  type: 'text',
  fields: 'college-university2',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 117',
  type: 'text',
  fields: 'college-university3',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 39',
  type: 'text',
  fields: 'major-university1',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 40',
  type: 'text',
  fields: 'major-university2',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 41',
  type: 'text',
  fields: 'major-university3',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 42',
  type: 'text',
  fields: 'ipk-university1',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 43',
  type: 'text',
  fields: 'ipk-university2',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 44',
  type: 'text',
  fields: 'ipk-university3',
  parent: 'univ_data'
}, {
  id: 'Combo Box 31',
  type: 'select',
  fields: 'year-university1',
  parent: 'univ_data'
}, {
  id: 'Combo Box 116',
  type: 'select',
  fields: 'year-university2',
  parent: 'univ_data'
}, {
  id: 'Combo Box 117',
  type: 'select',
  fields: 'year-university3',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 46',
  type: 'text',
  fields: 'place-job1',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 47',
  type: 'text',
  fields: 'position-job1',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 48',
  type: 'text',
  fields: 'place-job2',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 49',
  type: 'text',
  fields: 'position-job2',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 50',
  type: 'text',
  fields: 'place-job3',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 51',
  type: 'text',
  fields: 'position-job3',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 52',
  type: 'text',
  fields: 'place-job4',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 53',
  type: 'text',
  fields: 'position-job4',
  parent: 'job_history_data'
}, {
  id: 'Combo Box 120',
  type: 'select',
  fields: 'start-job1',
  parent: 'job_history_data'
}, {
  id: 'Combo Box 121',
  type: 'select',
  fields: 'end-job1',
  parent: 'job_history_data'
}, {
  id: 'Combo Box 122',
  type: 'select',
  fields: 'start-job2',
  parent: 'job_history_data'
}, {
  id: 'Combo Box 123',
  type: 'select',
  fields: 'end-job2',
  parent: 'job_history_data'
}, {
  id: 'Combo Box 124',
  type: 'select',
  fields: 'start-job3',
  parent: 'job_history_data'
}, {
  id: 'Combo Box 125',
  type: 'select',
  fields: 'end-job3',
  parent: 'job_history_data'
}, {
  id: 'Combo Box 126',
  type: 'select',
  fields: 'start-job4',
  parent: 'job_history_data'
}, {
  id: 'Combo Box 127',
  type: 'select',
  fields: 'end-job4',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 54',
  type: 'text',
  fields: 'position-service1',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 55',
  type: 'text',
  fields: 'position-service2',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 56',
  type: 'text',
  fields: 'position-service3',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 57',
  type: 'text',
  fields: 'position-service4',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 58',
  type: 'text',
  fields: 'church-service4',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 59',
  type: 'text',
  fields: 'church-service3',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 60',
  type: 'text',
  fields: 'church-service2',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 61',
  type: 'text',
  fields: 'church-service1',
  parent: 'service_history_data'
}, {
  id: 'Combo Box 129',
  type: 'select',
  fields: 'start-service1',
  parent: 'service_history_data'
}, {
  id: 'Combo Box 130',
  type: 'select',
  fields: 'end-service1',
  parent: 'service_history_data'
}, {
  id: 'Combo Box 131',
  type: 'select',
  fields: 'start-service2',
  parent: 'service_history_data'
}, {
  id: 'Combo Box 132',
  type: 'select',
  fields: 'end-service2',
  parent: 'service_history_data'
}, {
  id: 'Combo Box 133',
  type: 'select',
  fields: 'start-service3',
  parent: 'service_history_data'
}, {
  id: 'Combo Box 134',
  type: 'select',
  fields: 'end-service3',
  parent: 'service_history_data'
}, {
  id: 'Combo Box 135',
  type: 'select',
  fields: 'start-service4',
  parent: 'service_history_data'
}, {
  id: 'Combo Box 136',
  type: 'select',
  fields: 'end-service4',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 62',
  type: 'text',
  fields: 'church_member',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 63',
  type: 'text',
  fields: 'church_address',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 64',
  type: 'text',
  fields: 'church_city',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 65',
  type: 'text',
  fields: 'church_postal_code',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 66',
  type: 'text',
  fields: 'church_phone',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 67',
  type: 'text',
  fields: 'church_email',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 68',
  type: 'text',
  fields: 'church_senior_pastor',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 69',
  type: 'text',
  fields: 'church_active',
  parent: 'church_member_data'
}, {
  id: 'RB_Dukungan Finansial',
  type: 'radio',
  fields: 'financial_data_answer',
  parent: 'financial_data',
  options: [{
    value: 'sendiri',
    select: 0
  }, {
    value: 'donatur pribadi',
    select: 1
  }, {
    value: 'keluarga',
    select: 2
  }, {
    value: 'gereja/ institusi',
    select: 3
  }]
}, {
  id: 'RB_Memiliki waktu teduh',
  type: 'radio',
  fields: 'is_silent_time',
  parent: 'personal_spiritual_data',
  options: [{
    value: true,
    select: 1
  }, {
    value: false,
    select: 0
  }]
}, {
  id: 'Combo Box 51',
  type: 'select',
  fields: 'spiritually_number',
  parent: 'personal_spiritual_data'
}, {
  id: 'Berapa kali baca alkitab',
  type: 'text',
  fields: 'full_bible_read_amount',
  parent: 'personal_spiritual_data'
}, {
  id: 'RB_Memiliki sahabat atau mentor',
  type: 'radio',
  fields: 'is_spiritual_friend',
  parent: 'personal_spiritual_data',
  options: [{
    value: true,
    select: 1
  }, {
    value: false,
    select: 0
  }]
}, {
  id: 'Text Field 5',
  type: 'text',
  fields: 'spiritual_friend_name',
  parent: 'personal_spiritual_data'
}, {
  id: 'Nama_Lengkap 71',
  type: 'text',
  fields: 'bad_habit',
  parent: 'personal_struggle_data'
}, {
  id: 'Nama_Lengkap 113',
  type: 'text',
  fields: 'overcome_effort',
  parent: 'personal_struggle_data'
}, {
  id: 'R3',
  type: 'radio',
  fields: 'is_ever_register_stt',
  parent: 'other_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Nama_Lengkap 114',
  type: 'text',
  fields: 'when_register_stt',
  parent: 'other_data'
}, {
  id: 'Nama_Lengkap 115',
  type: 'text',
  fields: 'which_register_stt',
  parent: 'other_data'
}, {
  id: 'Text Field 40',
  type: 'text',
  fields: 'other_desc',
  parent: 'other_data'
}, {
  id: 'Check Box 217',
  type: 'checkBox',
  fields: 'is_alumni',
  parent: 'other_data'
}, {
  id: 'Check Box 218',
  type: 'checkBox',
  fields: 'is_servant_of_god',
  parent: 'other_data'
}, {
  id: 'Check Box 219',
  type: 'checkBox',
  fields: 'is_friend',
  parent: 'other_data'
}, {
  id: 'Check Box 220',
  type: 'checkBox',
  fields: 'is_lecturer',
  parent: 'other_data'
}, {
  id: 'Check Box 221',
  type: 'checkBox',
  fields: 'is_college_student',
  parent: 'other_data'
}, {
  id: 'Check Box 222',
  type: 'checkBox',
  fields: 'is_family',
  parent: 'other_data'
}, {
  id: 'Check Box 223',
  type: 'checkBox',
  fields: 'is_website',
  parent: 'other_data'
}, {
  id: 'Check Box 224',
  type: 'checkBox',
  fields: 'is_social_media',
  parent: 'other_data'
}, {
  id: 'Check Box 225',
  type: 'checkBox',
  fields: 'is_communication_media',
  parent: 'other_data'
}, {
  id: 'Check Box 226',
  type: 'checkBox',
  fields: 'is_poster',
  parent: 'other_data'
}, {
  id: 'Check Box 227',
  type: 'checkBox',
  fields: 'is_other',
  parent: 'other_data'
}, {
  id: 'Text Field 41',
  type: 'text',
  fields: 'sign_place',
  parent: 'agreement_data'
}, {
  id: 'Text Field 43',
  type: 'text',
  fields: 'guardian_name',
  parent: 'agreement_data'
}, {
  id: 'Text Field 42',
  type: 'text',
  fields: 'fullname',
  parent: 'personal_data'
}, {
  id: 'sign',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'parent_sign',
  type: 'file',
  fields: 'guardian_sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Combo Box 113',
  type: 'date',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Combo Box 114',
  type: 'month',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Combo Box 115',
  type: 'year',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 122',
  type: 'text',
  fields: 'name_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 123',
  type: 'text',
  fields: 'name_b',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 124',
  type: 'text',
  fields: 'email_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 125',
  type: 'text',
  fields: 'email_b',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 126',
  type: 'text',
  fields: 'phone_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 127',
  type: 'text',
  fields: 'phone_b',
  parent: 'recommendation_form'
}, {
  id: 'Text Field 22',
  type: 'text',
  fields: 'repentance_story',
  parent: 'repentance_story'
}, {
  id: 'Text Field 23',
  type: 'text',
  fields: 'calling_faith_story',
  parent: 'calling_faith_story'
}, {
  id: 'Text Field 24',
  type: 'text',
  fields: 'motivation_story',
  parent: 'motivation_story'
}]

export const mmin = [{
  id: 'Check Box 1',
  type: 'checkbox',
  fields: 'is_registration_form',
  parent: 'completion'
}, {
  id: 'Check Box 2',
  type: 'checkbox',
  fields: 'is_repentance_testimony_form',
  parent: 'completion'
}, {
  id: 'Check Box 6',
  type: 'checkbox',
  fields: 'is_motivation_form',
  parent: 'completion'
}, {
  id: 'Check Box 3',
  type: 'checkbox',
  fields: 'is_couple_agreement_form',
  parent: 'completion'
}, {
  id: 'Check Box 4',
  type: 'checkbox',
  fields: 'is_financial_support_form',
  parent: 'completion'
}, {
  id: 'Check Box 5',
  type: 'checkbox',
  fields: 'is_recommendation_form_result',
  parent: 'completion'
}, {
  id: 'Check Box 11',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 12',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 13',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 228',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 230',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 231',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  // TRANSFER
  id: 'Check Box 16',
  type: 'checkbox',
  fields: 'payment_method',
  parent: 'payment',
  value: "Transfer"
}, {
  id: 'Button3',
  type: 'radio',
  fields: 'concentration',
  parent: 'concentration',
  option: [{
    value: 'Pelayanan Kristen',
    select: '<1>'
  }, {
    value: 'Pelayanan di Ruang Publik',
    select: '<2>'
  }]
}, {
  id: 'Nama_Lengkap',
  type: 'text',
  fields: 'fullname',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 1',
  type: 'text',
  fields: 'birth_place',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 2',
  type: 'text',
  fields: 'nationality',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 3',
  type: 'text',
  fields: 'email',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 4',
  type: 'text',
  fields: 'address',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 6',
  type: 'text',
  fields: 'city',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 8',
  type: 'text',
  fields: 'phone',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 11',
  type: 'text',
  fields: 'id_passport_number',
  parent: 'personal_data'
}, {
  id: 'Combo Box 3',
  type: 'date',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Combo Box 4',
  type: 'month',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Text Field 44',
  type: 'year',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Jenis Kelamin',
  type: 'radio',
  fields: 'gender',
  parent: 'personal_data',
  options: [{
    value: 'Laki-laki',
    select: 0
  }, {
    value: 'Perempuan',
    select: 1
  }]
}, {
  id: 'Button1',
  type: 'file',
  fields: 'self_pict_url',
  parent: 'personal_data'
}, {
  id: 'Combo Box 132',
  type: 'select',
  fields: 'birth_order',
  parent: 'family_data'
}, {
  id: 'Combo Box 133',
  type: 'select',
  fields: 'total_sibling',
  parent: 'family_data'
}, {
  id: 'Button4',
  type: 'radio',
  fields: 'is_marriage',
  parent: 'marriage_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Button5',
  type: 'radio',
  fields: 'is_divorce',
  parent: 'marriage_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Gangguan Jiwa',
  type: 'radio',
  fields: 'is_mental_illness',
  parent: 'health_history_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Text Field 65',
  type: 'text',
  fields: 'mental_illness_desc',
  parent: 'health_history_data'
}, {
  id: 'Nama_Lengkap 35',
  type: 'text',
  fields: 'mental_illness_family',
  parent: 'health_history_data'
}, {
  id: 'Text Field 66',
  type: 'text',
  fields: 'illness_desc',
  parent: 'health_history_data'
}, {
  id: 'Nama_Lengkap 45',
  type: 'text',
  fields: 'college-university1',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 116',
  type: 'text',
  fields: 'college-university2',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 117',
  type: 'text',
  fields: 'college-university3',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 39',
  type: 'text',
  fields: 'major-university1',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 40',
  type: 'text',
  fields: 'major-university2',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 41',
  type: 'text',
  fields: 'major-university3',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 42',
  type: 'text',
  fields: 'ipk-university1',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 43',
  type: 'text',
  fields: 'ipk-university2',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 44',
  type: 'text',
  fields: 'ipk-university3',
  parent: 'univ_data'
}, {
  id: 'Text Field 45',
  type: 'text',
  fields: 'year-university1',
  parent: 'univ_data'
}, {
  id: 'Text Field 46',
  type: 'text',
  fields: 'year-university2',
  parent: 'univ_data'
}, {
  id: 'Text Field 47',
  type: 'text',
  fields: 'year-university3',
  parent: 'univ_data'
}, {
  id: 'Nama_Lengkap 46',
  type: 'text',
  fields: 'place-job1',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 47',
  type: 'text',
  fields: 'position-job1',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 48',
  type: 'text',
  fields: 'place-job2',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 49',
  type: 'text',
  fields: 'position-job2',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 50',
  type: 'text',
  fields: 'place-job3',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 51',
  type: 'text',
  fields: 'position-job3',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 52',
  type: 'text',
  fields: 'place-job4',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 53',
  type: 'text',
  fields: 'position-job4',
  parent: 'job_history_data'
}, {
  id: 'Text Field 48',
  type: 'text',
  fields: 'start-job1',
  parent: 'job_history_data'
}, {
  id: 'Text Field 49',
  type: 'text',
  fields: 'end-job1',
  parent: 'job_history_data'
}, {
  id: 'Text Field 50',
  type: 'text',
  fields: 'start-job2',
  parent: 'job_history_data'
}, {
  id: 'Text Field 51',
  type: 'text',
  fields: 'end-job2',
  parent: 'job_history_data'
}, {
  id: 'Text Field 52',
  type: 'text',
  fields: 'start-job3',
  parent: 'job_history_data'
}, {
  id: 'Text Field 53',
  type: 'text',
  fields: 'end-job3',
  parent: 'job_history_data'
}, {
  id: 'Text Field 54',
  type: 'text',
  fields: 'start-job4',
  parent: 'job_history_data'
}, {
  id: 'Text Field 55',
  type: 'text',
  fields: 'end-job4',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 71',
  type: 'text',
  fields: 'church_member',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 93',
  type: 'text',
  fields: 'church_address',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 94',
  type: 'text',
  fields: 'church_city',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 95',
  type: 'text',
  fields: 'church_phone',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 96',
  type: 'text',
  fields: 'church_email',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 118',
  type: 'text',
  fields: 'church_senior_pastor',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 119',
  type: 'text',
  fields: 'church_active',
  parent: 'church_member_data'
}, {
  id: 'Text Field 67',
  type: 'text',
  fields: 'bad_habit',
  parent: 'personal_struggle_data'
}, {
  id: 'Text Field 68',
  type: 'text',
  fields: 'overcome_effort',
  parent: 'personal_struggle_data'
}, {
  id: 'Button6',
  type: 'radio',
  fields: 'is_ever_register_stt',
  parent: 'other_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Button7',
  type: 'radio',
  fields: 'is_ever_finish_stt',
  parent: 'other_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Nama_Lengkap 120',
  type: 'text',
  fields: 'when_register_stt',
  parent: 'other_data'
}, {
  id: 'Nama_Lengkap 115',
  type: 'text',
  fields: 'which_register_stt',
  parent: 'other_data'
}, {
  id: 'Nama_Lengkap 114',
  type: 'text',
  fields: 'other_desc',
  parent: 'other_data'
}, {
  id: 'Check Box 236',
  type: 'checkBox',
  fields: 'is_alumni',
  parent: 'other_data'
}, {
  id: 'Check Box 237',
  type: 'checkBox',
  fields: 'is_servant_of_god',
  parent: 'other_data'
}, {
  id: 'Check Box 238',
  type: 'checkBox',
  fields: 'is_friend',
  parent: 'other_data'
}, {
  id: 'Check Box 239',
  type: 'checkBox',
  fields: 'is_lecturer',
  parent: 'other_data'
}, {
  id: 'Check Box 240',
  type: 'checkBox',
  fields: 'is_college_student',
  parent: 'other_data'
}, {
  id: 'Check Box 241',
  type: 'checkBox',
  fields: 'is_family',
  parent: 'other_data'
}, {
  id: 'Check Box 242',
  type: 'checkBox',
  fields: 'is_website',
  parent: 'other_data'
}, {
  id: 'Check Box 243',
  type: 'checkBox',
  fields: 'is_social_media',
  parent: 'other_data'
}, {
  id: 'Check Box 244',
  type: 'checkBox',
  fields: 'is_communication_media',
  parent: 'other_data'
}, {
  id: 'Check Box 245',
  type: 'checkBox',
  fields: 'is_poster',
  parent: 'other_data'
}, {
  id: 'Check Box 246',
  type: 'checkBox',
  fields: 'is_other',
  parent: 'other_data'
}, {
  id: 'Text Field 58',
  type: 'text',
  fields: 'fullname',
  parent: 'personal_data'
}, {
  id: 'Button2',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Combo Box 134',
  type: 'date',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Combo Box 135',
  type: 'month',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Text Field 59',
  type: 'year',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 123',
  type: 'text',
  fields: 'name_a',
  parent: 'recommendation_form'
},  {
  id: 'Nama_Lengkap 125',
  type: 'text',
  fields: 'email_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 127',
  type: 'text',
  fields: 'phone_a',
  parent: 'recommendation_form'
}, {
  id: 'Text Field 42',
  type: 'text',
  fields: 'repentance_story',
  parent: 'repentance_story'
}, {
  id: 'Text Field 24',
  type: 'text',
  fields: 'motivation_story',
  parent: 'motivation_story'
}]

export const mth = [{
  id: 'Check Box 1',
  type: 'checkbox',
  fields: 'is_registration_form',
  parent: 'completion'
}, {
  id: 'Check Box 2',
  type: 'checkbox',
  fields: 'is_motivation_form',
  parent: 'completion'
}, {
  id: 'Check Box 5',
  type: 'checkbox',
  fields: 'is_couple_agreement_form',
  parent: 'completion'
}, {
  id: 'Check Box 3',
  type: 'checkbox',
  fields: 'is_financial_support_form',
  parent: 'completion'
}, {
  id: 'Check Box 4',
  type: 'checkbox',
  fields: 'is_recommendation_form_result',
  parent: 'completion'
}, {
  id: 'Check Box 9',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 10',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 11',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 12',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 13',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 228',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  // TRANSFER
  id: 'Check Box 229',
  type: 'checkbox',
  fields: 'payment_method',
  parent: 'payment',
  value: "Transfer"
}, {
  id: 'Button3',
  type: 'radio',
  fields: 'concentration',
  parent: 'concentration',
  byIndex: true,
  option: [{
    value: 'Studi Biblika',
    select: 0
  }, {
    value: 'Pelayanan Pastoral',
    select: 1
  }, {
    value: 'Teologi Sistematika',
    select: 2
  }, {
    value: 'Pelayanan Kaum Muda',
    select: 3
  }]
}, {
  id: 'Nama_Lengkap',
  type: 'text',
  fields: 'fullname',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 1',
  type: 'text',
  fields: 'birth_place',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 2',
  type: 'text',
  fields: 'nationality',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 3',
  type: 'text',
  fields: 'email',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 4',
  type: 'text',
  fields: 'address',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 6',
  type: 'text',
  fields: 'city',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 8',
  type: 'text',
  fields: 'phone',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 11',
  type: 'text',
  fields: 'id_passport_number',
  parent: 'personal_data'
}, {
  id: 'Combo Box 3',
  type: 'date',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Combo Box 4',
  type: 'month',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Text Field 54',
  type: 'year',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Jenis Kelamin',
  type: 'radio',
  fields: 'gender',
  parent: 'personal_data',
  options: [{
    value: 'Laki-laki',
    select: 0
  }, {
    value: 'Perempuan',
    select: 1
  }]
}, {
  id: 'Button1',
  type: 'file',
  fields: 'self_pict_url',
  parent: 'personal_data'
}, {
  id: 'Combo Box 141',
  type: 'select',
  fields: 'birth_order',
  parent: 'family_data'
}, {
  id: 'Combo Box 142',
  type: 'select',
  fields: 'total_sibling',
  parent: 'family_data'
}, {
  id: 'Button4',
  type: 'radio',
  fields: 'is_marriage',
  parent: 'marriage_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Button6',
  type: 'radio',
  fields: 'is_divorce',
  parent: 'marriage_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Gangguan Jiwa',
  type: 'radio',
  fields: 'is_mental_illness',
  parent: 'health_history_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Text Field 66',
  type: 'text',
  fields: 'mental_illness_desc',
  parent: 'health_history_data'
}, {
  id: 'Nama_Lengkap 35',
  type: 'text',
  fields: 'mental_illness_family',
  parent: 'health_history_data'
}, {
  id: 'Text Field 67',
  type: 'text',
  fields: 'illness_desc',
  parent: 'health_history_data'
}, {
  id: 'Nama_Lengkap 46',
  type: 'text',
  fields: 'place-job1',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 47',
  type: 'text',
  fields: 'position-job1',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 48',
  type: 'text',
  fields: 'place-job2',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 49',
  type: 'text',
  fields: 'position-job2',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 50',
  type: 'text',
  fields: 'place-job3',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 51',
  type: 'text',
  fields: 'position-job3',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 52',
  type: 'text',
  fields: 'place-job4',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 53',
  type: 'text',
  fields: 'position-job4',
  parent: 'job_history_data'
}, {
  id: 'Text Field 46',
  type: 'text',
  fields: 'start-job1',
  parent: 'job_history_data'
}, {
  id: 'Text Field 47',
  type: 'text',
  fields: 'end-job1',
  parent: 'job_history_data'
}, {
  id: 'Text Field 48',
  type: 'text',
  fields: 'start-job2',
  parent: 'job_history_data'
}, {
  id: 'Text Field 49',
  type: 'text',
  fields: 'end-job2',
  parent: 'job_history_data'
}, {
  id: 'Text Field 50',
  type: 'text',
  fields: 'start-job3',
  parent: 'job_history_data'
}, {
  id: 'Text Field 51',
  type: 'text',
  fields: 'end-job3',
  parent: 'job_history_data'
}, {
  id: 'Text Field 53',
  type: 'text',
  fields: 'start-job4',
  parent: 'job_history_data'
}, {
  id: 'Text Field 52',
  type: 'text',
  fields: 'end-job4',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 70',
  type: 'text',
  fields: 'church_member',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 71',
  type: 'text',
  fields: 'church_address',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 93',
  type: 'text',
  fields: 'church_city',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 94',
  type: 'text',
  fields: 'church_phone',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 95',
  type: 'text',
  fields: 'church_email',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 115',
  type: 'text',
  fields: 'church_senior_pastor',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 116',
  type: 'text',
  fields: 'church_active',
  parent: 'church_member_data'
}, {
  id: 'Text Field 68',
  type: 'text',
  fields: 'bad_habit',
  parent: 'personal_struggle_data'
}, {
  id: 'Text Field 69',
  type: 'text',
  fields: 'overcome_effort',
  parent: 'personal_struggle_data'
}, {
  id: 'Button7',
  type: 'radio',
  fields: 'is_ever_register_stt',
  parent: 'other_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Button8',
  type: 'radio',
  fields: 'is_ever_finish_stt',
  parent: 'other_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Nama_Lengkap 117',
  type: 'text',
  fields: 'when_register_stt',
  parent: 'other_data'
}, {
  id: 'Nama_Lengkap 114',
  type: 'text',
  fields: 'which_register_stt',
  parent: 'other_data'
}, {
  id: 'Nama_Lengkap 113',
  type: 'text',
  fields: 'other_desc',
  parent: 'other_data'
}, {
  id: 'Check Box 217',
  type: 'checkBox',
  fields: 'is_alumni',
  parent: 'other_data'
}, {
  id: 'Check Box 218',
  type: 'checkBox',
  fields: 'is_servant_of_god',
  parent: 'other_data'
}, {
  id: 'Check Box 219',
  type: 'checkBox',
  fields: 'is_friend',
  parent: 'other_data'
}, {
  id: 'Check Box 220',
  type: 'checkBox',
  fields: 'is_lecturer',
  parent: 'other_data'
}, {
  id: 'Check Box 221',
  type: 'checkBox',
  fields: 'is_college_student',
  parent: 'other_data'
}, {
  id: 'Check Box 222',
  type: 'checkBox',
  fields: 'is_family',
  parent: 'other_data'
}, {
  id: 'Check Box 223',
  type: 'checkBox',
  fields: 'is_website',
  parent: 'other_data'
}, {
  id: 'Check Box 235',
  type: 'checkBox',
  fields: 'is_social_media',
  parent: 'other_data'
}, {
  id: 'Check Box 236',
  type: 'checkBox',
  fields: 'is_communication_media',
  parent: 'other_data'
}, {
  id: 'Check Box 237',
  type: 'checkBox',
  fields: 'is_poster',
  parent: 'other_data'
}, {
  id: 'Check Box 238',
  type: 'checkBox',
  fields: 'is_other',
  parent: 'other_data'
}, {
  id: 'Text Field 56',
  type: 'text',
  fields: 'fullname',
  parent: 'personal_data'
}, {
  id: 'Button2',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Combo Box 137',
  type: 'date',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Combo Box 138',
  type: 'month',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Text Field 57',
  type: 'year',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 121',
  type: 'text',
  fields: 'name_a',
  parent: 'recommendation_form'
},  {
  id: 'Nama_Lengkap 123',
  type: 'text',
  fields: 'email_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 125',
  type: 'text',
  fields: 'phone_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 122',
  type: 'text',
  fields: 'name_b',
  parent: 'recommendation_form'
},  {
  id: 'Nama_Lengkap 124',
  type: 'text',
  fields: 'email_b',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 126',
  type: 'text',
  fields: 'phone_b',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 127',
  type: 'text',
  fields: 'name_c',
  parent: 'recommendation_form'
},  {
  id: 'Nama_Lengkap 128',
  type: 'text',
  fields: 'email_c',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 129',
  type: 'text',
  fields: 'phone_c',
  parent: 'recommendation_form'
}, {
  id: 'Text Field 24',
  type: 'text',
  fields: 'motivation_story',
  parent: 'motivation_story'
}]
export const mthu = [{
  id: 'Check Box 1',
  type: 'checkbox',
  fields: 'is_registration_form',
  parent: 'completion'
}, {
  id: 'Check Box 2',
  type: 'checkbox',
  fields: 'is_repentance_testimony_form',
  parent: 'completion'
}, {
  id: 'Check Box 7',
  type: 'checkbox',
  fields: 'is_faith_calling_form',
  parent: 'completion'
}, {
  id: 'Check Box 4',
  type: 'checkbox',
  fields: 'is_couple_agreement_form',
  parent: 'completion'
}, {
  id: 'Check Box 5',
  type: 'checkbox',
  fields: 'is_health_form',
  parent: 'completion'
}, {
  id: 'Check Box 6',
  type: 'checkbox',
  fields: 'is_financial_support_form',
  parent: 'completion'
}, {
  id: 'Check Box 8',
  type: 'checkbox',
  fields: 'is_recommendation_form_result',
  parent: 'completion'
}, {
  id: 'Check Box 9',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 11',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 12',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 13',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 14',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  id: 'Check Box 199',
  type: 'checkbox',
  fields: 'is_complete_file',
  parent: 'completion'
}, {
  // TRANSFER
  id: 'Check Box 16',
  type: 'checkbox',
  fields: 'payment_method',
  parent: 'payment',
  value: "Transfer"
}, {
  id: 'Button2',
  type: 'radio',
  fields: 'concentration',
  parent: 'concentration',
  byIndex: true,
  option: [{
    value: 'Studi Biblika',
    select: 0
  }, {
    value: 'Teologi Sistematika',
    select: 1
  }, {
    value: 'Praktika',
    select: 2
  }]
}, {
  id: 'Nama_Lengkap',
  type: 'text',
  fields: 'fullname',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 1',
  type: 'text',
  fields: 'birth_place',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 2',
  type: 'text',
  fields: 'nationality',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 3',
  type: 'text',
  fields: 'email',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 4',
  type: 'text',
  fields: 'address',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 6',
  type: 'text',
  fields: 'city',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 8',
  type: 'text',
  fields: 'phone',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 11',
  type: 'text',
  fields: 'id_passport_number',
  parent: 'personal_data'
}, {
  id: 'Combo Box 3',
  type: 'date',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Combo Box 4',
  type: 'month',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Text Field 72',
  type: 'year',
  fields: 'birth_date',
  parent: 'personal_data'
}, {
  id: 'Jenis Kelamin',
  type: 'radio',
  fields: 'gender',
  parent: 'personal_data',
  options: [{
    value: 'Laki-laki',
    select: 0
  }, {
    value: 'Perempuan',
    select: 1
  }]
}, {
  id: 'Button1',
  type: 'file',
  fields: 'self_pict_url',
  parent: 'personal_data'
}, {
  id: 'Nama_Lengkap 13',
  type: 'text',
  fields: 'name-1',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 14',
  type: 'text',
  fields: 'name-2',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 17',
  type: 'text',
  fields: 'phone-1',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 18',
  type: 'text',
  fields: 'phone-2',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 93',
  type: 'text',
  fields: 'job-1',
  parent: 'family_data'
}, {
  id: 'Nama_Lengkap 110',
  type: 'text',
  fields: 'job-2',
  parent: 'family_data'
}, {
  id: 'Combo Box 13',
  type: 'select',
  fields: 'birth_order',
  parent: 'family_data'
}, {
  id: 'Combo Box 14',
  type: 'select',
  fields: 'total_sibling',
  parent: 'family_data'
}, {
  id: 'Combo Box 11',
  type: 'select',
  fields: 'role-1',
  parent: 'family_data'
}, {
  id: 'Combo Box 12',
  type: 'select',
  fields: 'role-2',
  parent: 'family_data'
}, {
  id: 'Button5',
  type: 'radio',
  fields: 'is_marriage',
  parent: 'marriage_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Button6',
  type: 'radio',
  fields: 'is_divorce',
  parent: 'marriage_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Gangguan Jiwa',
  type: 'radio',
  fields: 'is_mental_illness',
  parent: 'health_history_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Text Field 2',
  type: 'text',
  fields: 'mental_illness_desc',
  parent: 'health_history_data'
}, {
  id: 'Nama_Lengkap 34',
  type: 'text',
  fields: 'mental_illness_family',
  parent: 'health_history_data'
}, {
  id: 'Nama_Lengkap 35',
  type: 'text',
  fields: 'illness_desc',
  parent: 'health_history_data'
}, {
  id: 'Text Field 3',
  type: 'text',
  fields: 'exist_illness_desc',
  parent: 'health_history_data'
}, {
  id: 'Nama_Lengkap 46',
  type: 'text',
  fields: 'place-job1',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 47',
  type: 'text',
  fields: 'position-job1',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 48',
  type: 'text',
  fields: 'place-job2',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 49',
  type: 'text',
  fields: 'position-job2',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 50',
  type: 'text',
  fields: 'place-job3',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 51',
  type: 'text',
  fields: 'position-job3',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 52',
  type: 'text',
  fields: 'place-job4',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 53',
  type: 'text',
  fields: 'position-job4',
  parent: 'job_history_data'
}, {
  id: 'Text Field 56',
  type: 'text',
  fields: 'start-job1',
  parent: 'job_history_data'
}, {
  id: 'Text Field 57',
  type: 'text',
  fields: 'end-job1',
  parent: 'job_history_data'
}, {
  id: 'Text Field 59',
  type: 'text',
  fields: 'start-job2',
  parent: 'job_history_data'
}, {
  id: 'Text Field 58',
  type: 'text',
  fields: 'end-job2',
  parent: 'job_history_data'
}, {
  id: 'Text Field 60',
  type: 'text',
  fields: 'start-job3',
  parent: 'job_history_data'
}, {
  id: 'Text Field 61',
  type: 'text',
  fields: 'end-job3',
  parent: 'job_history_data'
}, {
  id: 'Text Field 63',
  type: 'text',
  fields: 'start-job4',
  parent: 'job_history_data'
}, {
  id: 'Text Field 62',
  type: 'text',
  fields: 'end-job4',
  parent: 'job_history_data'
}, {
  id: 'Nama_Lengkap 55',
  type: 'text',
  fields: 'position-service1',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 56',
  type: 'text',
  fields: 'position-service2',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 57',
  type: 'text',
  fields: 'position-service3',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 58',
  type: 'text',
  fields: 'position-service4',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 59',
  type: 'text',
  fields: 'church-service4',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 60',
  type: 'text',
  fields: 'church-service3',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 61',
  type: 'text',
  fields: 'church-service2',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 113',
  type: 'text',
  fields: 'church-service1',
  parent: 'service_history_data'
}, {
  id: 'Text Field 64',
  type: 'text',
  fields: 'start-service1',
  parent: 'service_history_data'
}, {
  id: 'Text Field 65',
  type: 'text',
  fields: 'end-service1',
  parent: 'service_history_data'
}, {
  id: 'Text Field 71',
  type: 'text',
  fields: 'start-service2',
  parent: 'service_history_data'
}, {
  id: 'Text Field 66',
  type: 'text',
  fields: 'end-service2',
  parent: 'service_history_data'
}, {
  id: 'Text Field 70',
  type: 'text',
  fields: 'start-service3',
  parent: 'service_history_data'
}, {
  id: 'Text Field 67',
  type: 'text',
  fields: 'end-service3',
  parent: 'service_history_data'
}, {
  id: 'Text Field 69',
  type: 'text',
  fields: 'start-service4',
  parent: 'service_history_data'
}, {
  id: 'Text Field 68',
  type: 'text',
  fields: 'end-service4',
  parent: 'service_history_data'
}, {
  id: 'Nama_Lengkap 64',
  type: 'text',
  fields: 'church_member',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 65',
  type: 'text',
  fields: 'church_address',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 66',
  type: 'text',
  fields: 'church_city',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 68',
  type: 'text',
  fields: 'church_phone',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 69',
  type: 'text',
  fields: 'church_email',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 114',
  type: 'text',
  fields: 'church_senior_pastor',
  parent: 'church_member_data'
}, {
  id: 'Nama_Lengkap 115',
  type: 'text',
  fields: 'church_active',
  parent: 'church_member_data'
}, {
  id: 'RB_Memiliki waktu teduh',
  type: 'radio',
  fields: 'is_silent_time',
  parent: 'personal_spiritual_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Combo Box 51',
  type: 'select',
  fields: 'spiritually_number',
  parent: 'personal_spiritual_data'
}, {
  id: 'Berapa kali baca alkitab',
  type: 'text',
  fields: 'full_bible_read_amount',
  parent: 'personal_spiritual_data'
}, {
  id: 'RB_Memiliki sahabat atau mentor',
  type: 'radio',
  fields: 'is_spiritual_friend',
  parent: 'personal_spiritual_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Text Field 5',
  type: 'text',
  fields: 'spiritual_friend_name',
  parent: 'personal_spiritual_data'
}, {
  id: 'Nama_Lengkap 117',
  type: 'text',
  fields: 'bad_habit',
  parent: 'personal_struggle_data'
}, {
  id: 'Nama_Lengkap 118',
  type: 'text',
  fields: 'overcome_effort',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 190',
  type: 'checkBox',
  fields: 'is_drug',
  parent: 'personal_struggle_data',
}, {
  id: 'Check Box 191',
  type: 'checkBox',
  fields: 'is_gaming',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 192',
  type: 'checkBox',
  fields: 'is_alcohol',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 193',
  type: 'checkBox',
  fields: 'is_smoking',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 194',
  type: 'checkBox',
  fields: 'is_gambling',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 195',
  type: 'checkBox',
  fields: 'is_porn',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 196',
  type: 'checkBox',
  fields: 'is_masturbate',
  parent: 'personal_struggle_data'
}, {
  id: 'Check Box 197',
  type: 'checkBox',
  fields: 'is_other',
  parent: 'personal_struggle_data',
},  {
  id: 'Button7',
  type: 'radio',
  fields: 'is_ever_register_stt',
  parent: 'other_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Button8',
  type: 'radio',
  fields: 'is_ever_finish_stt',
  parent: 'other_data',
  options: [{
    value: true,
    select: 0
  }, {
    value: false,
    select: 1
  }]
}, {
  id: 'Nama_Lengkap 111',
  type: 'text',
  fields: 'when_register_stt',
  parent: 'other_data'
}, {
  id: 'Nama_Lengkap 112',
  type: 'text',
  fields: 'which_register_stt',
  parent: 'other_data'
}, {
  id: 'Text Field 6',
  type: 'text',
  fields: 'other_desc',
  parent: 'other_data'
}, {
  id: 'Check Box 31',
  type: 'checkBox',
  fields: 'is_alumni',
  parent: 'other_data'
}, {
  id: 'Check Box 32',
  type: 'checkBox',
  fields: 'is_servant_of_god',
  parent: 'other_data'
}, {
  id: 'Check Box 33',
  type: 'checkBox',
  fields: 'is_friend',
  parent: 'other_data'
}, {
  id: 'Check Box 34',
  type: 'checkBox',
  fields: 'is_lecturer',
  parent: 'other_data'
}, {
  id: 'Check Box 36',
  type: 'checkBox',
  fields: 'is_college_student',
  parent: 'other_data'
}, {
  id: 'Check Box 37',
  type: 'checkBox',
  fields: 'is_family',
  parent: 'other_data'
}, {
  id: 'Check Box 38',
  type: 'checkBox',
  fields: 'is_website',
  parent: 'other_data'
}, {
  id: 'Check Box 39',
  type: 'checkBox',
  fields: 'is_social_media',
  parent: 'other_data'
}, {
  id: 'Check Box 40',
  type: 'checkBox',
  fields: 'is_communication_media',
  parent: 'other_data'
}, {
  id: 'Check Box 41',
  type: 'checkBox',
  fields: 'is_poster',
  parent: 'other_data'
}, {
  id: 'Check Box 42',
  type: 'checkBox',
  fields: 'is_other',
  parent: 'other_data'
}, {
  id: 'Text Field 49',
  type: 'text',
  fields: 'other_desc',
  parent: 'personal_struggle_data',
}, {
  id: 'Text Field 52',
  type: 'text',
  fields: 'guardian_name',
  parent: 'agreement_data'
}, {
  id: 'Text Field 51',
  type: 'text',
  fields: 'fullname',
  parent: 'personal_data'
}, {
  id: 'Button3',
  type: 'file',
  fields: 'sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Button4',
  type: 'file',
  fields: 'guardian_sign_pict_url',
  parent: 'agreement_data'
}, {
  id: 'Combo Box 156',
  type: 'date',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Combo Box 157',
  type: 'month',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Text Field 73',
  type: 'year',
  fields: 'sign_date',
  parent: 'agreement_data'
}, {
  id: 'Nama_Lengkap 123',
  type: 'text',
  fields: 'name_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 124',
  type: 'text',
  fields: 'name_b',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 125',
  type: 'text',
  fields: 'email_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 126',
  type: 'text',
  fields: 'email_b',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 127',
  type: 'text',
  fields: 'phone_a',
  parent: 'recommendation_form'
}, {
  id: 'Nama_Lengkap 128',
  type: 'text',
  fields: 'phone_b',
  parent: 'recommendation_form'
}, {
  id: 'Text Field 22',
  type: 'text',
  fields: 'repentance_story',
  parent: 'repentance_story'
}, {
  id: 'Text Field 23',
  type: 'text',
  fields: 'calling_faith_story',
  parent: 'calling_faith_story'
}]